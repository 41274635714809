<template>
  <page-container3>
    <div class="ant-pro-table">
      <div class="ant-pro-table-search" ref="searchEle">
        <search-render
          ref="config_render"
          :form="searchConfig"
          :model="searchConfig.model"
          :options="searchConfigOptions"
          :validateInfos="validateInfos"
          :resetFields="resetFields"
          @search="search"
        />
      </div>
      <a-card :body-style="{ padding: 0 }" ref="elRef">
        <div class="ant-pro-table-list-toolbar">
          <div class="ant-pro-table-list-toolbar-container">
            <div class="ant-pro-table-list-toolbar-left">
              <div class="ant-pro-table-list-toolbar-title">{{ titleList.title }}</div>
            </div>
            <div class="ant-pro-table-list-toolbar-right">
              <a-space align="center">
                <a-button v-if="titleList.is_create" type="primary" size="small" @click="handleAdd">
                  <plus-outlined />
                  {{ titleList.addModalTitle }}
                </a-button>
                <a-button
                  v-for="btn in head_btn_list"
                  size="small"
                  :key="btn.name"
                  @click="btn.cb(t)"
                  :type="btn.clazz"
                >
                  {{ (t && t(`${btn.name}`)) || btn.name }}
                </a-button>
                <!--                <a-button size="small" @click="send_pdf" type="primary">批量生成PDF</a-button>-->
                <a-button size="small" type="primary" @click="send_email">{{ t('批量手动发邮件') }}</a-button>
                <a-button size="small" type="primary" @click="download_pdf">{{ t('批量下载PDF') }}</a-button>

                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-tooltip :title="t('展示自定义列')">
                    <a-switch
                      :checked-children="t('用户')"
                      :un-checked-children="t('原始')"
                      v-model:checked="configState.checkAll"
                      @change="column_change()"
                    />
                  </a-tooltip>
                </div>
                <div
                  class="ant-pro-table-list-toolbar-setting-item"
                  v-if="configState.showCheckAction"
                >
                  <a-tooltip :title="t('显示/隐藏操作列')">
                    <a-switch
                      :checked-children="t('操作')"
                      :un-checked-children="t('操作')"
                      v-model:checked="configState.checkAction"
                      @change="columnAction()"
                    />
                  </a-tooltip>
                </div>
              </a-space>
              <div class="ant-pro-table-list-toolbar-divider">
                <a-divider type="vertical" />
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('刷新')">
                  <reload-outlined @click="handleTableChange" />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('导出Excel')">
                  <export-excel-modal
                    :column-list="dynamicColumnItems"
                    :datasource="state.dataSource"
                    :filename="titleList.title"
                    :columns="dynamicColumns"
                    :slotList="slotList"
                    :pageSize="state.pageSize"
                    :current="state.current"
                  />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('密度')">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <column-height-outlined />
                    <template #overlay>
                      <a-menu
                        style="width: 80px"
                        :selected-keys="[state.tableSize]"
                        @click="
                          ({ key }) => {
                            state.tableSize = key;
                          }
                        "
                      >
                        <a-menu-item key="default">
                          <a href="javascript:">{{ t('默认') }}</a>
                        </a-menu-item>
                        <a-menu-item key="middle">
                          <a href="javascript:">{{ t('中等') }}</a>
                        </a-menu-item>
                        <a-menu-item key="small">
                          <a href="javascript:">{{ t('紧凑') }}</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-popover
                  placement="bottomRight"
                  arrowPointAtCenter
                  trigger="click"
                  overlayClassName="ant-pro-table-column-setting-overlay"
                >
                  <template #title>
                    <div class="ant-pro-table-column-setting-title">
                      <a-checkbox
                        v-model:checked="columnState.checkAll"
                        :indeterminate="columnState.indeterminate"
                        @change="handleColumnAllClick"
                      >
                        {{ t('列展示') }}
                      </a-checkbox>
                      <a v-if="!configState.is_column_config" @click="switch_column_config">{{ t('配置') }}</a>
                      <a @click="switch_column_config" v-if="configState.is_column_config">
                        {{ t('关闭配置') }}
                      </a>
                    </div>
                  </template>
                  <template #content>
                    <span class="ant-pro-table-column-setting-list">
                      <drag-container
                        style="width: 280px; height: 300px; overflow: scroll"
                        lockAxis="y"
                        dragClass="ant-pro-table-drag-ghost"
                        dropClass="ant-pro-table-drop-ghost"
                        @drop="
                          ({ removedIndex, addedIndex }) => {
                            move(removedIndex, addedIndex);
                            if (!configState.is_column_config) {
                              update_column_data();
                            }
                          }
                        "
                      >
                        <draggable :key="column.key" v-for="column in dynamicColumnItems">
                          <div
                            class="ant-pro-table-column-setting-list-item"
                            v-show="configState.is_column_config ? true : column.org_checked"
                          >
                            <drag-icon />
                            <a-checkbox
                              :checked="column.checked"
                              @change="
                                $event => {
                                  handleColumnChange($event, column);
                                  if (!configState.is_column_config) {
                                    update_column_data();
                                  }
                                }
                              "
                            >
                              {{ column.label }}
                            </a-checkbox>
                            <a-switch
                              v-if="configState.is_column_config"
                              :checked-children="t('显示')"
                              :un-checked-children="t('隐藏')"
                              v-model:checked="column.org_checked"
                              @change="
                                () => {
                                  if (!column.org_checked) {
                                    column.checked = false;
                                  }
                                  handleColumnChange(
                                    { target: { checked: column.checked } },
                                    column,
                                  );
                                }
                              "
                            />
                          </div>
                        </draggable>
                      </drag-container>
                    </span>
                  </template>
                  <a-tooltip :title="t('列设置')">
                    <setting-outlined />
                  </a-tooltip>
                </a-popover>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="screenState ? t('退出全屏') : t('全屏')">
                  <fullscreen-outlined v-if="!screenState" @click="setFull" />
                  <fullscreen-exit-outlined v-else @click="exitFull" />
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <!--        <a-affix :offset-top="top">-->
        <a-table
          bordered="true"
          :scroll="{ y: tableHeight.y, x: columnState.tableWidth }"
          :row-class-name="stripe"
          :row-selection="rowSelection"
          :size="state.tableSize"
          :loading="state.loading"
          :columns="dynamicColumns"
          :data-source="state.dataSource"
          :indentSize="0"
          :rowKey="'id'"
          :pagination="{
            current: state.current,
            pageSize: state.pageSize,
            total: state.total,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['50', '100', '200', '500', '1000', '2000'],
          }"
          @change="handleTableChange"
        >
          <template
            v-for="item in slotList"
            v-slot:[item.slotName]="{ index, text, record }"
            :key="item.slotName"
          >
            <span v-if="item.slotName === 'index'">
              {{ index + 1 + state.pageSize * (state.current - 1) }}
            </span>
            <span v-if="item.type === 'custom'">
              {{ item['field_' + text] }}
            </span>
            <span v-if="item.type === 'custom1'">
              {{ item.content(record) }}
            </span>
            <span v-if="item.type === 'datetime'">
              {{ getDateTime(text) }}
            </span>
            <span v-if="item.type === 'date'">
              {{ getDate(text) }}
            </span>
            <span v-if="item.type === 'month'">
              {{ getMonth(text) }}
            </span>
            <p v-if="item.type === 'remark'" style="white-space: pre-line">
              {{ text }}
            </p>
            <span v-if="item.type === 'time'">
              {{ getTime(text) }}
            </span>
            <span v-if="item.type === 'percent'">{{ text }}%</span>
            <span v-if="item.type === 'toFixed2'">
              {{ text.toFixed(2) }}
            </span>
            <span v-if="item.type === 'money'">
              {{ $numeral.money(text) }}
            </span>
            <span v-if="item.type === 'red'" :style="item.cb(record) ? 'color: #f5222d' : ''">
              {{ text }}
            </span>

            <!--使用槽 click 列宽设定 200-->
            <a-button
              style="padding-left: 1px"
              v-if="item.type === 'click'"
              type="link"
              @click="item.cb(record)"
              :title="item.content(record) ? item.content(record) : text"
            >
              <span
                v-if="item.content"
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  display: inline-block;
                  width: 180px;
                "
              >
                {{ item.content(record) }}
              </span>
              <span
                v-else
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  display: inline-block;
                  width: 180px;
                "
              >
                {{ text }}
              </span>
            </a-button>
            <template v-if="item.type === 'clickList'">
              <template v-for="file in text" :key="file.file_id">
                <a-button
                  style="padding: 1px"
                  type="link"
                  @click="item.cb(file)"
                  :title="file.name"
                >
                  <a-tooltip>
                    <span
                      style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: inline-block;
                        width: 150px;
                      "
                    >
                      {{ file.name }}
                    </span>
                  </a-tooltip>
                </a-button>
                <delete-outlined @click="item.del(record, text, file)" style="color: #ff0000" />
              </template>
            </template>
            <span v-if="item.type === 'color'" :style="item.cb ? item.cb(record) : ''">
              <span v-if="item.content">
                {{ item.content(record) }}
              </span>
              <span v-else>
                {{ text }}
              </span>
            </span>
            <a-tag color="green" v-if="text && item.type === 'boolean'">{{ item.name1 }}</a-tag>
            <a-tag color="red" v-if="!text && item.type === 'boolean'">{{ item.name2 }}</a-tag>
            <template v-if="item.type === 'list'">
              <a-tag color="#87d068" v-for="obj in text" :key="obj.id" style="margin-bottom: 1px">
                {{ obj.name }}
              </a-tag>
            </template>
          </template>
          <template #action="{ record }">
            <a-button
              type="primary"
              size="small"
              @click="() => handleOpenEdit(record)"
              v-if="record.is_active && titleList.is_update && !column_dropdown_btn_dict"
            >
              {{ t('修改') }}
            </a-button>
            <a-button
              v-for="btn in column_normal_btn_list"
              size="small"
              v-show="btn?.is_show ? btn.is_show(record) : true"
              :key="btn.name"
              :type="btn.clazz"
              @click="btn.cb(record, t)"
            >
              {{ (t && t(`${btn.name}`)) || btn.name }}
            </a-button>
            <a-button
              size="small"
              type="danger"
              @click="() => handleDelete(record)"
              v-if="record.is_active && titleList.is_remove && !column_dropdown_btn_dict"
            >
              {{ titleList.del_btn ? titleList.del_btn : '删除' }}
            </a-button>

            <a-button
              size="small"
              type="dashed"
              @click="() => handleRecover(record)"
              v-if="!record.is_active && titleList.is_recover && !column_dropdown_btn_dict"
            >
              {{ t('恢复') }}
            </a-button>
            <template v-if="column_dropdown_btn_dict">
              <a-dropdown-button
                @click="column_dropdown_btn_dict.dropdown_btn.cb(record, t)"
                size="small"
              >
                {{
                  (t && t(`${column_dropdown_btn_dict.dropdown_btn.name}`)) || column_dropdown_btn_dict.dropdown_btn.name
                }}
                <template #overlay>
                  <a-menu>
                    <a-menu-item
                      :key="btn.name"
                      :disabled="btn?.is_show ? !btn.is_show(record) : false"
                      @click="btn.cb(record, t)"
                      v-for="btn in column_dropdown_btn_dict.btn_list"
                    >
                      {{ (t && t(`${btn.name}`)) || btn.name }}
                    </a-menu-item>

                    <a-menu-item
                      key="update"
                      v-if="record.is_active && titleList.is_update"
                      @click="handleOpenEdit(record)"
                    >
                      <edit-outlined />
                      {{ t('修改') }}
                    </a-menu-item>
                    <a-menu-item
                      key="update"
                      v-if="record.is_active && titleList.is_remove"
                      @click="handleDelete(record)"
                    >
                      <delete-outlined />
                      {{ t('删除') }}
                    </a-menu-item>
                    <a-menu-item
                      key="update"
                      v-if="!record.is_active && titleList.is_recover"
                      @click="handleRecover(record)"
                    >
                      <redo-outlined />
                      {{ t('恢复') }}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown-button>
            </template>
          </template>
        </a-table>
        <!--        </a-affix>-->
      </a-card>
    </div>
    <form-modal
      v-bind="editModalAdd"
      v-if="editModalAdd.visible"
      :loading="confirmLoading"
      @cancel="
        () => {
          editModalAdd.visible = false;
        }
      "
      @ok="handlerOk"
    />
    <form-modal
      v-bind="editModalUpdate"
      v-if="editModalUpdate.visible"
      :loading="confirmLoading"
      @cancel="
        () => {
          editModalUpdate.visible = false;
        }
      "
      @ok="handlerOk"
    />
  </page-container3>
</template>

<script lang="ts">
import {
  computed,
  createVNode,
  defineComponent,
  onActivated,
  onDeactivated,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import {
  ColumnHeightOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  RedoOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { Pagination, TableColumn, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '@/components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { message, Modal, notification } from 'ant-design-vue';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import getPageConfig from '@/views/profile/basic-invoice1/index';
import { useRoute } from 'vue-router';
import { Record } from '@/components/page-model/typing';
import { bulk_create_payslip_pdf, bulk_pay_payslip, bulk_send_invoice_by_manul } from '@/api/cerp-model';
import { useI18n } from 'vue-i18n';
import { debounce } from 'lodash-es';
import bus from '@/utils/bus';

export default defineComponent({
  name: 'PageModel5',
  props: {},
  setup() {
    const confirmLoading = ref<boolean>(false);
    const store = useStore();
    const { t } = useI18n();
    const current_org = store.getters['user/current_org'];
    const custome_data_user = store.getters['user/custome_data_user'];
    const current_company = computed(() => store.getters['user/current_company']);
    const checkAll = ref<boolean>(false);
    const checkAction = ref<boolean>(false);
    const paysliplist: any = ref([]);
    const file_ids: any = ref([]);
    const ids: any = ref([]);
    const modalRef = reactive({
      selectedRows: new Array<number>(),
    });
    let formModalUpdate = false; // 判断显示创建、修改
    const searchEle:any = ref();
    const tableHeight = reactive({ y: window.innerHeight - 295 });
    const onresize:any = debounce(()=>{
      if(searchEle.value){
        tableHeight.y = window.innerHeight - searchEle.value.offsetHeight - 295;
      }else{
        tableHeight.y = window.innerHeight - 295;
      }
    }, 200);
    onMounted(() => {
      window.onresize = onresize;
      onresize();
    });

    const route = useRoute();
    const path_arr = route.path.split('/');
    const field = {
      modalType: path_arr[path_arr.length - 1],
      column_flag: 'column_' + path_arr[path_arr.length - 1],
    };
    const local_path = route.path;

    watch(
      () => route.path,
      () => {
        if (route.path == local_path && !route.query.q) {
          reload();
        }
      },
    );

    // 时间区间格式转化 数组-->字符串
    const getRangePicker = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string].join(',');
        }
      }
      return obj;
    };

    const {
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      queryData,
      addData,
      updateData,
      removeData,
      recoverData,
      baseColumns,
      slotList,
      titleList,
      api_fields,
      column_list_change,
      btn_list,
      head_btn_list,
      event_obj,
    } = getPageConfig(field.modalType);

    // 操作列 按钮分为下拉和普通
    const column_normal_btn_list: any = computed(() => {
      return btn_list?.filter((item: any) => item.type == undefined || item.type == 'btn');
    });
    const column_dropdown_btn_dict: any = computed(() => {
      const l = btn_list?.filter((item: any) => item.type == 'dropdown');
      if (l && l.length > 0) {
        return { dropdown_btn: l[0], btn_list: l.slice(1) };
      } else {
        return null;
      }
    });

    if (searchConfig.fields) {
      for (let i = 0; i < searchConfig.fields.length; i++) {
        // 预先填充 placeholder
        let filed_dict = searchConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }

        // 预先填充 校验message
        let search_rule_dict = searchConfig.rules[filed_dict.name];
        if (search_rule_dict) {
          if (!search_rule_dict.message) {
            search_rule_dict.message = filed_dict.placeholder;
          }
        }
      }
    }

    if (formModalConfig.fields) {
      for (let i = 0; i < formModalConfig.fields.length; i++) {
        let filed_dict = formModalConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }
      }
    }

    const event_obj_refresh_search = (query_data: any) => {
      Object.assign(searchConfig.model, query_data);
      search();
    };
    bus.on('selectCompany', () => {
      Object.assign(searchConfig.model, {
        cc_company_name: current_company.value?.name !== '全部公司' ? current_company.value?.name : '',
        cc_company_id: current_company.value?.id,
      });
      search();
    });
    const event_obj_reload = () => {
      reload();
    };

    event_obj?.on('refresh_search', event_obj_refresh_search);
    event_obj?.on('refresh', event_obj_reload);
    event_obj?.on('reset_and_reload', event_obj_reload);
    onActivated(() => {
      reload();
      event_obj?.off('refresh_search', event_obj_refresh_search);
      event_obj?.off('refresh', event_obj_reload);
      event_obj?.off('reset_and_reload', event_obj_reload);
      // 先解监听，再监听，防止重复
      event_obj?.on('refresh_search', event_obj_refresh_search);
      event_obj?.on('refresh', event_obj_reload);
      event_obj?.on('reset_and_reload', event_obj_reload);
    });
    onDeactivated(() => {
      event_obj?.off('refresh_search', event_obj_refresh_search);
      event_obj?.off('refresh', event_obj_reload);
      event_obj?.off('reset_and_reload', event_obj_reload);
    });

    let tmpBaseColumns = null;
    if (custome_data_user && custome_data_user[field.column_flag]) {
      baseColumns.map((item: any, index: number) => {
        if (custome_data_user[field.column_flag][item.dataIndex]) {
          item.sort_num = index;
          item.sort_num = custome_data_user[field.column_flag][item.dataIndex]['index'];
          item.checked = custome_data_user[field.column_flag][item.dataIndex]['checked'];
        }
      });
      tmpBaseColumns = baseColumns.sort((a: any, b: any) => a.sort_num - b.sort_num);
    }
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
      configState,
      update_column_org_config,
      update_column_data,
      resizeColumn,
      switch_column_config,
      columnAction,
      column_change,
      replace_column,
    } = useTableDynamicColumns(
      baseColumns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
      store,
      field.column_flag,
      column_list_change,
    );

    // watch(
    //   () => dynamicColumnItems,
    //   () => {
    //     const column_data_json: any = {};
    //     dynamicColumnItems.value.map((item, index) => {
    //       column_data_json[item.key] = { key: item.key, index: index, checked: item.checked };
    //     });
    //
    //     set_custom_data_by_flag({
    //       org_id: current_org?.id,
    //       flag: field.column_flag,
    //       data: JSON.stringify(column_data_json),
    //     }).then(function () {
    //       store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, { org_id: Number(current_org?.id) });
    //     });
    //   },
    //   { deep: true },
    // );
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);

    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryData, {
      current: 1,
      pageSize: 20,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...searchConfig.model,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
      },
    });
    let sortFieldList:Array<string> = [];
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: any,
    ) => {
      let sort_fields: any = [];
      if(sorter instanceof Array){
        sortFieldList = sortFieldList.filter((field:string)=>{
          return sorter.find((item:any)=>field==item.column.dataIndex)!=undefined;
        });
        sorter.map((item:any)=>{
          if(!sortFieldList.includes(item.column.dataIndex)){
            sortFieldList.push(item.column.dataIndex)
          }
        });
        sort_fields = sortFieldList.map((field:string)=>{
          if(sorter.find((item:any)=>field==item.column.dataIndex).order=='descend'){
            return `-${field}`;
          }else{
            return field;
          }
        });
      }else if(sorter != undefined && sorter.column != undefined){
        sortFieldList.length = 0;
        sortFieldList.push(sorter.column.dataIndex);

        if(sorter.order == 'ascend'){
          sort_fields.push(sorter.column.dataIndex);
        }
        if(sorter.order == 'descend'){
          sort_fields.push('-' + sorter.column.dataIndex);
        }
      }else{
        sortFieldList.length = 0;
      }
      let filter_map: any = {};
      for (let key in filters) {
        filter_map[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...searchConfig.model,
        service_months: !searchConfig.model.service_month1 && !searchConfig.model.service_month2 ? null : [searchConfig.model.service_month1, searchConfig.model.service_month2],
        ...filter_map,
        sort_fields: sort_fields,
      });
      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...searchConfig.model,
        service_months: !searchConfig.model.service_month1 && !searchConfig.model.service_month2 ? null : [searchConfig.model.service_month1, searchConfig.model.service_month2],
        ids: null,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
      });
      reload();
    };
    // edit
    const editModalAdd = reactive({
      visible: false,
      title: '',
      form: formModalConfig,
      options: formModalConfigOptions,
    });
    const editModalUpdate = reactive({
      visible: false,
      title: '',
      form: updateFormModalConfig,
      options: updateFormModalConfigOptions,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      formModalUpdate = true;
      editModalUpdate.visible = true;
      editModalUpdate.title = titleList.updateTitle;
      editModalUpdate.form.model = {
        ...updateFormModalConfig.model,
        ...record,
        ...getObjList(api_fields.update, record),
      };

      currentRecord = record;
    };
    const handleAdd = () => {
      formModalUpdate = false;
      editModalAdd.visible = true;

      editModalAdd.form.model = { ...formModalConfig.model };
      editModalAdd.title = titleList.addModalTitle;
    };

    const handleDelete = (record: any) => {
      Modal.confirm({
        title: t('是否删除此项记录') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            removeData({
              org_id: current_org.id,
              ...getObjList(api_fields.delete, record),
            }).then((res: any) => {
              message.success(t('删除成功'));
              Object.assign(record, res);
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {},
      });
    };
    const handleRecover = (record: any) => {
      recoverData({
        org_id: current_org.id,
        ...getObjList(api_fields.recover, record),
      }).then((res: any) => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      confirmLoading.value = true;
      if (!formModalUpdate) {
        addData({ ...data })
          .then(() => {
            message.success(t('创建成功'));
            reload();
            editModalAdd.visible = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      } else {
        updateData({
          ...data,
          ...getObjList(api_fields.updateData, data),
          ...getObjList(api_fields.updateData, editModalUpdate.form.model),
        })
          .then((res: any) => {
            Object.assign(currentRecord, res);
            message.success(t('修改成功'));
            editModalUpdate.visible = false;
            formModalUpdate = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      }
    };

    // 字段对应错误————>转化正确
    const getObjList = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string];
        }
      }
      return obj;
    };

    // 接口返回时间，格式优化
    const getDateTime = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 16);
      } else if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const getDate = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 10);
      } else if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };
    const getMonth = (time: any) => {
      if (time) {
        time = time.substring(0, 7);
      }
      return time;
    };
    const getTime = (time: any) => {
      if (time) {
        time = time.substring(0, 5);
      }
      return time;
    };

    // 列’默认‘ ’全部‘ 展示的切换
    // let old_list: any[] = []; // 定义old_list记录默认数据
    // const column_change = () => {
    //   if (checkAll.value) {
    //     dynamicColumnItems.value.forEach(org => {
    //       old_list.push(org);
    //       let b = {
    //         ...org,
    //         checked: true,
    //       };
    //       let a: any = {};
    //       a.target = b;
    //       handleColumnChange(a, b);
    //     });
    //   } else {
    //     old_list.forEach(org => {
    //       let a: any = {};
    //       a.target = org;
    //       handleColumnChange(a, org);
    //     });
    //   }
    // };
    // const columnAction = () => {
    //   dynamicColumnItems.value.forEach(org => {
    //     if (org.key == 'action') {
    //       let b = {
    //         ...org,
    //         checked: checkAction.value,
    //       };
    //       let a: any = {};
    //       a.target = b;
    //       handleColumnChange(a, b);
    //     }
    //   });
    // };
    const rowSelection = {
      type: 'checkbox',
      selectedRowKeys: modalRef.selectedRows,
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log('selectedRows', selectedRows);
        console.log('selectedRowKeys', selectedRowKeys);
        modalRef.selectedRows.length = 0;
        file_ids.value.length = 0;
        selectedRowKeys.map((item: any) => {
          modalRef.selectedRows.push(item);
        })
        selectedRows.map((item: any) => {
          if(item.files_json && item.files_json.file_id){
            file_ids.value.push(item.files_json.file_id);
          }
        })
      },
    };
    const pay = () => {
      if (modalRef.selectedRows.length == 0) {
        notification.error({
          message: t('提示'),
          description: t('请选择需要付款的工资单'),
        });
        return;
      }
      Modal.confirm({
        title: t('是否确认批量付款') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            bulk_pay_payslip({
              org_id: current_org.id,
              payslip_ids: modalRef.selectedRows,
            }).then(() => {
              message.success(t('操作成功'));
              modalRef.selectedRows.length = 0;
              reload();
            }).finally(() => {
              resolve(null);
            });
          })
        },
        onCancel() {},
      });
    };
    const download_pdf = () => {
      if (modalRef.selectedRows.length == 0) {
        notification.error({
          message: t('提示'),
          description: t('请选择需要下载PDF的请款单'),
        });
        return;
      }
      Modal.confirm({
        title: t('是否确认批量下载PDF') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          const url: string = '/nsbcs/download_zip_file?fileids=' + encodeURIComponent(file_ids.value);
          window.open(url, '_blank');
          modalRef.selectedRows.length = 0;
        },
        onCancel() {
        },
      });
    };
    const send_pdf = () => {
      if (modalRef.selectedRows.length == 0) {
        notification.error({
          message: t('提示'),
          description: t('请选择需要生成PDF的工资单'),
        });
        return;
      }
      Modal.confirm({
        title: t('是否确认生成PDF') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            bulk_create_payslip_pdf({
              org_id: current_org.id,
              payslip_ids: modalRef.selectedRows,
            }).then(() => {
              message.success(t('操作成功'));
              ids.value = JSON.parse(JSON.stringify(modalRef.selectedRows));
              modalRef.selectedRows.length = 0;
              refresh();
            }).finally(() => {
              resolve(null);
            });
          })
        },
        onCancel() {},
      });
    };
    const refresh = () => {
      queryData({
        org_id: current_org?.id,
        ids: ids.value,
      }).then((res: any) => {
        let is_refresh = false;
        res.data.map((item1: any) => {
          state.dataSource.map((item: any) => {
            if(item.id == item1.id){
              item.email_record__status = item1.email_record__status;
              item.email_record__log = item1.email_record__log;
              item.email_record__create_time = item1.email_record__create_time;
              item.email_record__update_time = item1.email_record__update_time;
              item.status = item1.status;
            }
          })
          if(item1.email_record__status == 2){
            message.error(item1.invoice_no + t('邮件发送失败'));
          }
          if (item1.status == 6 && item1.email_record__status == 0){
            is_refresh = true;
          }
        })
        if (is_refresh) {
          setTimeout(() => {
            refresh();
          }, 1000);
        }
      });
    };
    const send_email = () => {
      if (modalRef.selectedRows.length == 0) {
        notification.error({
          message: t('提示'),
          description: t('请选择需要发送邮件的请款单'),
        });
        return;
      }
      Modal.confirm({
        title: t('是否确认发送邮件') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            bulk_send_invoice_by_manul({
              org_id: current_org.id,
              invoice_ids: modalRef.selectedRows,
            }).then(() => {
              message.success(t('操作成功'));
              modalRef.selectedRows.length = 0;
              refresh();
            }).finally(() => {
              resolve(null);
            });
          })
        },
        onCancel() {},
      });
    };
    return {
      pay,
      download_pdf,
      send_pdf,
      send_email,
      getDateTime,
      getMonth,
      getTime,
      getDate,
      getRangePicker,
      column_change,
      replace_column,
      configState,
      update_column_org_config,
      update_column_data,
      resizeColumn,
      switch_column_config,
      columnAction,
      checkAll,
      rowSelection,
      checkAction,
      slotList,
      titleList,
      tableHeight,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      confirmLoading,
      handleAdd,
      handlerOk,
      currentRecord,
      resetFields,
      validateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      editModalAdd,
      editModalUpdate,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      getPageConfig,
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      getObjList,
      field,
      api_fields,
      column_normal_btn_list,
      column_dropdown_btn_dict,
      head_btn_list,
      t,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    ExclamationCircleOutlined,
    RedoOutlined,
    EditOutlined,
    DeleteOutlined,
    Draggable,
    DragContainer,
    FormModal,
    SearchRender,
  },
});
</script>
