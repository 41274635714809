
import {
  computed,
  createVNode,
  defineComponent,
  onActivated,
  onDeactivated,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import {
  ColumnHeightOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  RedoOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { Pagination, TableColumn, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '@/components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { message, Modal, notification } from 'ant-design-vue';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import getPageConfig from '@/views/profile/basic-invoice1/index';
import { useRoute } from 'vue-router';
import { Record } from '@/components/page-model/typing';
import { bulk_create_payslip_pdf, bulk_pay_payslip, bulk_send_invoice_by_manul } from '@/api/cerp-model';
import { useI18n } from 'vue-i18n';
import { debounce } from 'lodash-es';
import bus from '@/utils/bus';

export default defineComponent({
  name: 'PageModel5',
  props: {},
  setup() {
    const confirmLoading = ref<boolean>(false);
    const store = useStore();
    const { t } = useI18n();
    const current_org = store.getters['user/current_org'];
    const custome_data_user = store.getters['user/custome_data_user'];
    const current_company = computed(() => store.getters['user/current_company']);
    const checkAll = ref<boolean>(false);
    const checkAction = ref<boolean>(false);
    const paysliplist: any = ref([]);
    const file_ids: any = ref([]);
    const ids: any = ref([]);
    const modalRef = reactive({
      selectedRows: new Array<number>(),
    });
    let formModalUpdate = false; // 判断显示创建、修改
    const searchEle:any = ref();
    const tableHeight = reactive({ y: window.innerHeight - 295 });
    const onresize:any = debounce(()=>{
      if(searchEle.value){
        tableHeight.y = window.innerHeight - searchEle.value.offsetHeight - 295;
      }else{
        tableHeight.y = window.innerHeight - 295;
      }
    }, 200);
    onMounted(() => {
      window.onresize = onresize;
      onresize();
    });

    const route = useRoute();
    const path_arr = route.path.split('/');
    const field = {
      modalType: path_arr[path_arr.length - 1],
      column_flag: 'column_' + path_arr[path_arr.length - 1],
    };
    const local_path = route.path;

    watch(
      () => route.path,
      () => {
        if (route.path == local_path && !route.query.q) {
          reload();
        }
      },
    );

    // 时间区间格式转化 数组-->字符串
    const getRangePicker = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string].join(',');
        }
      }
      return obj;
    };

    const {
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      queryData,
      addData,
      updateData,
      removeData,
      recoverData,
      baseColumns,
      slotList,
      titleList,
      api_fields,
      column_list_change,
      btn_list,
      head_btn_list,
      event_obj,
    } = getPageConfig(field.modalType);

    // 操作列 按钮分为下拉和普通
    const column_normal_btn_list: any = computed(() => {
      return btn_list?.filter((item: any) => item.type == undefined || item.type == 'btn');
    });
    const column_dropdown_btn_dict: any = computed(() => {
      const l = btn_list?.filter((item: any) => item.type == 'dropdown');
      if (l && l.length > 0) {
        return { dropdown_btn: l[0], btn_list: l.slice(1) };
      } else {
        return null;
      }
    });

    if (searchConfig.fields) {
      for (let i = 0; i < searchConfig.fields.length; i++) {
        // 预先填充 placeholder
        let filed_dict = searchConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }

        // 预先填充 校验message
        let search_rule_dict = searchConfig.rules[filed_dict.name];
        if (search_rule_dict) {
          if (!search_rule_dict.message) {
            search_rule_dict.message = filed_dict.placeholder;
          }
        }
      }
    }

    if (formModalConfig.fields) {
      for (let i = 0; i < formModalConfig.fields.length; i++) {
        let filed_dict = formModalConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }
      }
    }

    const event_obj_refresh_search = (query_data: any) => {
      Object.assign(searchConfig.model, query_data);
      search();
    };
    bus.on('selectCompany', () => {
      Object.assign(searchConfig.model, {
        cc_company_name: current_company.value?.name !== '全部公司' ? current_company.value?.name : '',
        cc_company_id: current_company.value?.id,
      });
      search();
    });
    const event_obj_reload = () => {
      reload();
    };

    event_obj?.on('refresh_search', event_obj_refresh_search);
    event_obj?.on('refresh', event_obj_reload);
    event_obj?.on('reset_and_reload', event_obj_reload);
    onActivated(() => {
      reload();
      event_obj?.off('refresh_search', event_obj_refresh_search);
      event_obj?.off('refresh', event_obj_reload);
      event_obj?.off('reset_and_reload', event_obj_reload);
      // 先解监听，再监听，防止重复
      event_obj?.on('refresh_search', event_obj_refresh_search);
      event_obj?.on('refresh', event_obj_reload);
      event_obj?.on('reset_and_reload', event_obj_reload);
    });
    onDeactivated(() => {
      event_obj?.off('refresh_search', event_obj_refresh_search);
      event_obj?.off('refresh', event_obj_reload);
      event_obj?.off('reset_and_reload', event_obj_reload);
    });

    let tmpBaseColumns = null;
    if (custome_data_user && custome_data_user[field.column_flag]) {
      baseColumns.map((item: any, index: number) => {
        if (custome_data_user[field.column_flag][item.dataIndex]) {
          item.sort_num = index;
          item.sort_num = custome_data_user[field.column_flag][item.dataIndex]['index'];
          item.checked = custome_data_user[field.column_flag][item.dataIndex]['checked'];
        }
      });
      tmpBaseColumns = baseColumns.sort((a: any, b: any) => a.sort_num - b.sort_num);
    }
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
      configState,
      update_column_org_config,
      update_column_data,
      resizeColumn,
      switch_column_config,
      columnAction,
      column_change,
      replace_column,
    } = useTableDynamicColumns(
      baseColumns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
      store,
      field.column_flag,
      column_list_change,
    );

    // watch(
    //   () => dynamicColumnItems,
    //   () => {
    //     const column_data_json: any = {};
    //     dynamicColumnItems.value.map((item, index) => {
    //       column_data_json[item.key] = { key: item.key, index: index, checked: item.checked };
    //     });
    //
    //     set_custom_data_by_flag({
    //       org_id: current_org?.id,
    //       flag: field.column_flag,
    //       data: JSON.stringify(column_data_json),
    //     }).then(function () {
    //       store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, { org_id: Number(current_org?.id) });
    //     });
    //   },
    //   { deep: true },
    // );
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);

    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryData, {
      current: 1,
      pageSize: 20,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...searchConfig.model,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
      },
    });
    let sortFieldList:Array<string> = [];
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: any,
    ) => {
      let sort_fields: any = [];
      if(sorter instanceof Array){
        sortFieldList = sortFieldList.filter((field:string)=>{
          return sorter.find((item:any)=>field==item.column.dataIndex)!=undefined;
        });
        sorter.map((item:any)=>{
          if(!sortFieldList.includes(item.column.dataIndex)){
            sortFieldList.push(item.column.dataIndex)
          }
        });
        sort_fields = sortFieldList.map((field:string)=>{
          if(sorter.find((item:any)=>field==item.column.dataIndex).order=='descend'){
            return `-${field}`;
          }else{
            return field;
          }
        });
      }else if(sorter != undefined && sorter.column != undefined){
        sortFieldList.length = 0;
        sortFieldList.push(sorter.column.dataIndex);

        if(sorter.order == 'ascend'){
          sort_fields.push(sorter.column.dataIndex);
        }
        if(sorter.order == 'descend'){
          sort_fields.push('-' + sorter.column.dataIndex);
        }
      }else{
        sortFieldList.length = 0;
      }
      let filter_map: any = {};
      for (let key in filters) {
        filter_map[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...searchConfig.model,
        service_months: !searchConfig.model.service_month1 && !searchConfig.model.service_month2 ? null : [searchConfig.model.service_month1, searchConfig.model.service_month2],
        ...filter_map,
        sort_fields: sort_fields,
      });
      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...searchConfig.model,
        service_months: !searchConfig.model.service_month1 && !searchConfig.model.service_month2 ? null : [searchConfig.model.service_month1, searchConfig.model.service_month2],
        ids: null,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
      });
      reload();
    };
    // edit
    const editModalAdd = reactive({
      visible: false,
      title: '',
      form: formModalConfig,
      options: formModalConfigOptions,
    });
    const editModalUpdate = reactive({
      visible: false,
      title: '',
      form: updateFormModalConfig,
      options: updateFormModalConfigOptions,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      formModalUpdate = true;
      editModalUpdate.visible = true;
      editModalUpdate.title = titleList.updateTitle;
      editModalUpdate.form.model = {
        ...updateFormModalConfig.model,
        ...record,
        ...getObjList(api_fields.update, record),
      };

      currentRecord = record;
    };
    const handleAdd = () => {
      formModalUpdate = false;
      editModalAdd.visible = true;

      editModalAdd.form.model = { ...formModalConfig.model };
      editModalAdd.title = titleList.addModalTitle;
    };

    const handleDelete = (record: any) => {
      Modal.confirm({
        title: t('是否删除此项记录') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            removeData({
              org_id: current_org.id,
              ...getObjList(api_fields.delete, record),
            }).then((res: any) => {
              message.success(t('删除成功'));
              Object.assign(record, res);
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {},
      });
    };
    const handleRecover = (record: any) => {
      recoverData({
        org_id: current_org.id,
        ...getObjList(api_fields.recover, record),
      }).then((res: any) => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      confirmLoading.value = true;
      if (!formModalUpdate) {
        addData({ ...data })
          .then(() => {
            message.success(t('创建成功'));
            reload();
            editModalAdd.visible = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      } else {
        updateData({
          ...data,
          ...getObjList(api_fields.updateData, data),
          ...getObjList(api_fields.updateData, editModalUpdate.form.model),
        })
          .then((res: any) => {
            Object.assign(currentRecord, res);
            message.success(t('修改成功'));
            editModalUpdate.visible = false;
            formModalUpdate = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      }
    };

    // 字段对应错误————>转化正确
    const getObjList = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string];
        }
      }
      return obj;
    };

    // 接口返回时间，格式优化
    const getDateTime = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 16);
      } else if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const getDate = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 10);
      } else if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };
    const getMonth = (time: any) => {
      if (time) {
        time = time.substring(0, 7);
      }
      return time;
    };
    const getTime = (time: any) => {
      if (time) {
        time = time.substring(0, 5);
      }
      return time;
    };

    // 列’默认‘ ’全部‘ 展示的切换
    // let old_list: any[] = []; // 定义old_list记录默认数据
    // const column_change = () => {
    //   if (checkAll.value) {
    //     dynamicColumnItems.value.forEach(org => {
    //       old_list.push(org);
    //       let b = {
    //         ...org,
    //         checked: true,
    //       };
    //       let a: any = {};
    //       a.target = b;
    //       handleColumnChange(a, b);
    //     });
    //   } else {
    //     old_list.forEach(org => {
    //       let a: any = {};
    //       a.target = org;
    //       handleColumnChange(a, org);
    //     });
    //   }
    // };
    // const columnAction = () => {
    //   dynamicColumnItems.value.forEach(org => {
    //     if (org.key == 'action') {
    //       let b = {
    //         ...org,
    //         checked: checkAction.value,
    //       };
    //       let a: any = {};
    //       a.target = b;
    //       handleColumnChange(a, b);
    //     }
    //   });
    // };
    const rowSelection = {
      type: 'checkbox',
      selectedRowKeys: modalRef.selectedRows,
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log('selectedRows', selectedRows);
        console.log('selectedRowKeys', selectedRowKeys);
        modalRef.selectedRows.length = 0;
        file_ids.value.length = 0;
        selectedRowKeys.map((item: any) => {
          modalRef.selectedRows.push(item);
        })
        selectedRows.map((item: any) => {
          if(item.files_json && item.files_json.file_id){
            file_ids.value.push(item.files_json.file_id);
          }
        })
      },
    };
    const pay = () => {
      if (modalRef.selectedRows.length == 0) {
        notification.error({
          message: t('提示'),
          description: t('请选择需要付款的工资单'),
        });
        return;
      }
      Modal.confirm({
        title: t('是否确认批量付款') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            bulk_pay_payslip({
              org_id: current_org.id,
              payslip_ids: modalRef.selectedRows,
            }).then(() => {
              message.success(t('操作成功'));
              modalRef.selectedRows.length = 0;
              reload();
            }).finally(() => {
              resolve(null);
            });
          })
        },
        onCancel() {},
      });
    };
    const download_pdf = () => {
      if (modalRef.selectedRows.length == 0) {
        notification.error({
          message: t('提示'),
          description: t('请选择需要下载PDF的请款单'),
        });
        return;
      }
      Modal.confirm({
        title: t('是否确认批量下载PDF') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          const url: string = '/nsbcs/download_zip_file?fileids=' + encodeURIComponent(file_ids.value);
          window.open(url, '_blank');
          modalRef.selectedRows.length = 0;
        },
        onCancel() {
        },
      });
    };
    const send_pdf = () => {
      if (modalRef.selectedRows.length == 0) {
        notification.error({
          message: t('提示'),
          description: t('请选择需要生成PDF的工资单'),
        });
        return;
      }
      Modal.confirm({
        title: t('是否确认生成PDF') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            bulk_create_payslip_pdf({
              org_id: current_org.id,
              payslip_ids: modalRef.selectedRows,
            }).then(() => {
              message.success(t('操作成功'));
              ids.value = JSON.parse(JSON.stringify(modalRef.selectedRows));
              modalRef.selectedRows.length = 0;
              refresh();
            }).finally(() => {
              resolve(null);
            });
          })
        },
        onCancel() {},
      });
    };
    const refresh = () => {
      queryData({
        org_id: current_org?.id,
        ids: ids.value,
      }).then((res: any) => {
        let is_refresh = false;
        res.data.map((item1: any) => {
          state.dataSource.map((item: any) => {
            if(item.id == item1.id){
              item.email_record__status = item1.email_record__status;
              item.email_record__log = item1.email_record__log;
              item.email_record__create_time = item1.email_record__create_time;
              item.email_record__update_time = item1.email_record__update_time;
              item.status = item1.status;
            }
          })
          if(item1.email_record__status == 2){
            message.error(item1.invoice_no + t('邮件发送失败'));
          }
          if (item1.status == 6 && item1.email_record__status == 0){
            is_refresh = true;
          }
        })
        if (is_refresh) {
          setTimeout(() => {
            refresh();
          }, 1000);
        }
      });
    };
    const send_email = () => {
      if (modalRef.selectedRows.length == 0) {
        notification.error({
          message: t('提示'),
          description: t('请选择需要发送邮件的请款单'),
        });
        return;
      }
      Modal.confirm({
        title: t('是否确认发送邮件') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            bulk_send_invoice_by_manul({
              org_id: current_org.id,
              invoice_ids: modalRef.selectedRows,
            }).then(() => {
              message.success(t('操作成功'));
              modalRef.selectedRows.length = 0;
              refresh();
            }).finally(() => {
              resolve(null);
            });
          })
        },
        onCancel() {},
      });
    };
    return {
      pay,
      download_pdf,
      send_pdf,
      send_email,
      getDateTime,
      getMonth,
      getTime,
      getDate,
      getRangePicker,
      column_change,
      replace_column,
      configState,
      update_column_org_config,
      update_column_data,
      resizeColumn,
      switch_column_config,
      columnAction,
      checkAll,
      rowSelection,
      checkAction,
      slotList,
      titleList,
      tableHeight,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      confirmLoading,
      handleAdd,
      handlerOk,
      currentRecord,
      resetFields,
      validateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      editModalAdd,
      editModalUpdate,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      getPageConfig,
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      getObjList,
      field,
      api_fields,
      column_normal_btn_list,
      column_dropdown_btn_dict,
      head_btn_list,
      t,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    ExclamationCircleOutlined,
    RedoOutlined,
    EditOutlined,
    DeleteOutlined,
    Draggable,
    DragContainer,
    FormModal,
    SearchRender,
  },
});
